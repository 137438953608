export const home = '/';
export const login = '/auth/login/:redirectUrl?';
export const companySignUpStep1 = '/auth/company/signup/1';
export const companySignUpStep2 = '/auth/company/signup/2';
export const companySignUpStep3 = '/auth/company/signup/3';
export const companyInviteSignUp = '/auth/company/invite-signup';
export const companySignUpComplete = '/auth/company/signup/complete';
export const communitySignUp = '/community/auth/signup';
export const search = '/search';
export const allCompanies = '/companies';
export const allCompanyUsers = '/company-users';
export const allTeam = '/team';
export const allProjects = '/projects';
export const projectDetail = '/project/:id';
export const allTalent = '/talent';
export const allPendingTalent = '/talent?status[]=0';
export const previewTalent = '/talent/:id';
export const editTalent = '/talent/edit/:id';
export const addTalent = '/talent/add';
export const activeCredits = '/credits/active-credits';
export const creditLog = '/credits/log';
export const tags = '/tags';
export const admins = '/admins';
export const myProfile = '/account';
export const integrations = '/integrations';
export const companyCredits = '/credits/company';
export const talentProfile = '/my-profile';
export const JDPreview = '/jd/:interviewGuid/:timestamp';
export const termsOfService = '/terms-of-service';
export const page404 = '*/404';
export const aiMatch = '/ai-match';
export const taDashboard = '/dashboard/ta-dashboard-weekly';
export const taAssignedActivity = '/dashboard/ta-assigned-activity';
export const taStageActivity = '/dashboard/ta-recruitment-activity';
export const taProjectOverview = '/dashboard/project-overview';
